import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'normalize.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import App from './App';
import store from './store';
import L10nProvider from 'components/L10nProvider/L10nProvider';
import ApiProvider from 'providers/ApiProvider';
import GlobalErrorsProvider from 'providers/GlobalErrorsProvider';
import GlobalToastProvider from 'providers/GlobalToastProvider';
import Page404 from 'components/Page404/Page404';
import { isLocalDev, login } from 'localDev/localDevHelpers';
import flippers from 'utils/flippers';

const styleNode = document.createElement('style');
document.head.insertBefore(styleNode, document.head.firstChild);

const render = async () => {
  if (isLocalDev()) {
    await login();
  }

  if (!flippers.flowDiagram) {
    document.body.classList.add('script-node-only');
  }

  ReactDOM.render(
    <Router>
      <StyleSheetManager target={styleNode}>
        <Provider store={store}>
          <L10nProvider>
            <GlobalErrorsProvider>
              <GlobalToastProvider>
                <ApiProvider>
                  <Switch>
                    <Route path={['/orgs/:orgId/robots/:robotId/apps/:appId']} component={App} />
                    <Route component={Page404} />
                  </Switch>
                </ApiProvider>
              </GlobalToastProvider>
            </GlobalErrorsProvider>
          </L10nProvider>
        </Provider>
      </StyleSheetManager>
    </Router>,
    document.getElementById('root'),
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}
